<tabset>
    <tab *ngFor="let category of m3Object" heading="{{ category.categoryTitle }}">
        <div class="card card-body">
            <div class="row">
                <div *ngFor="let object of category.objects" class="col-6">
                    <div class="form-group row">
                        <label class="col-form-label col-12 col-md-6" [class.font-weight-bold]="object.objectName.includes('boxes')">{{
                            object.objectTitle
                        }}</label>
                        <div class="col-12 col-md-6">
                            <div class="input-group input-group-m3">
                                <button
                                    class="btn btn-outline-secondary"
                                    (click)="editAmount(category.categoryName, object.objectName, -1)"
                                >
                                    <fa-icon [icon]="['fal', 'minus']" [fixedWidth]="true"></fa-icon>
                                </button>
                                <input
                                    type="number"
                                    class="form-control text-center"
                                    [formControl]="form.get(category.categoryName + '.' + object.objectName)"
                                    (input)="editAmount(category.categoryName, object.objectName, 0)"
                                />
                                <button class="btn btn-outline-secondary" (click)="editAmount(category.categoryName, object.objectName, 1)">
                                    <fa-icon [icon]="['fal', 'plus']" [fixedWidth]="true"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <small class="form-text text-muted">
                        {{ category.categoryComment }}
                    </small>
                </div>
            </div>
        </div>
    </tab>
</tabset>
