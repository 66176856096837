import { NgFor, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { M3CalculatorService } from 'app/_services/m3-calculator.service'
import { TabsModule } from 'ngx-bootstrap/tabs'

@Component({
    selector: 'm3-calc-objects',
    templateUrl: './objects-tabset.component.html',
    standalone: true,
    imports: [NgFor, NgIf, TabsModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
})
export class M3ObjectsTabsetComponent {
    @Input() m3Object
    @Input() form

    constructor(private m3CalculatorService: M3CalculatorService) {}

    editAmount(categoryName: string, objectName: string, amount: number) {
        const object = this.form.get(categoryName).get(objectName)

        // Check if we do not cross limits
        const value = Math.min(Math.max(Math.floor(object.value + amount), 0), 100)
        if (object.value !== null && value !== object.value) {
            object.patchValue(value)
        }
        this.m3CalculatorService.calculateTotal(this.form, this.m3Object)
    }
}
