import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { tap } from 'rxjs/operators'
import { AuthService } from './auth.service'
import { SharedService } from './shared.service'
import { FormBuilder } from '@angular/forms'

@Injectable({
    providedIn: 'root',
})
export class M3CalculatorService {
    constructor(private authService: AuthService, private sharedService: SharedService, private router: Router, private fb: FormBuilder) {}

    read(params, queryParams) {
        const admin = queryParams.admin === 'true'
        const bootswatch = queryParams.bootswatch
        const language = params.language

        const opts = { params: { admin } }

        // Remove one time params
        this.router.navigate([], { queryParams: { admin, bootswatch } })

        const url = `/api/form/m3-calculator/${language}`
        return this.authService.get(url, opts).pipe(tap((res) => this.sharedService.companyUpdate(res.data.company, bootswatch)))
    }

    calculateTotal(form, m3Object) {
        let total = 0
        m3Object.forEach((category) => {
            const categoryForm = form.get(category.categoryName)
            let categoryTotal = 0
            category.objects.forEach((object) => {
                categoryTotal += categoryForm.get(object.objectName).value * object.m3
            })
            categoryForm.get('total').patchValue(Math.round(categoryTotal * 100) / 100)
            total += categoryTotal
        })
        form.get('total').patchValue(Math.round(total * 100) / 100)
    }

    private createM3CategoryGroup(categoryName, objects) {
        const group = { total: 0 }
        objects.forEach((object) => {
            group[object.objectName] = 0
        })
        return group
    }

    createM3CategoryGroupHolder(m3Object) {
        const formGroup = { total: 0 }
        m3Object.forEach((category) => {
            formGroup[category.categoryName] = this.fb.group(this.createM3CategoryGroup(category.categoryName, category.objects))
        })
        return formGroup
    }
}
